import React from "react";

const InviteSvg = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7266 4.81348C14.6657 4.81348 13.6483 5.2349 12.8981 5.98505C12.148 6.73519 11.7266 7.75261 11.7266 8.81348C11.7266 9.87434 12.148 10.8918 12.8981 11.6419C13.6483 12.392 14.6657 12.8135 15.7266 12.8135C16.7874 12.8135 17.8048 12.392 18.555 11.6419C19.3051 10.8918 19.7266 9.87434 19.7266 8.81348C19.7266 7.75261 19.3051 6.73519 18.555 5.98505C17.8048 5.2349 16.7874 4.81348 15.7266 4.81348ZM15.7266 6.71348C16.8866 6.71348 17.8266 7.65348 17.8266 8.81348C17.8266 9.97348 16.8866 10.9135 15.7266 10.9135C15.1696 10.9135 14.6355 10.6922 14.2416 10.2984C13.8478 9.90457 13.6266 9.37043 13.6266 8.81348C13.6266 8.25652 13.8478 7.72238 14.2416 7.32855C14.6355 6.93473 15.1696 6.71348 15.7266 6.71348ZM4.72656 7.81348V10.8135H1.72656V12.8135H4.72656V15.8135H6.72656V12.8135H9.72656V10.8135H6.72656V7.81348H4.72656ZM15.7266 13.8135C13.0566 13.8135 7.72656 15.1435 7.72656 17.8135V20.8135H23.7266V17.8135C23.7266 15.1435 18.3966 13.8135 15.7266 13.8135ZM15.7266 15.7135C18.6966 15.7135 21.8266 17.1735 21.8266 17.8135V18.9135H9.62656V17.8135C9.62656 17.1735 12.7266 15.7135 15.7266 15.7135Z"
        fill={props.selectedKeys === "6" || props.selectedKeys === "9" ? "#fff" : "#0B649D"}
      />
    </svg>
  );
};

export default InviteSvg;
