import React from "react";

const MyNotesSvg = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8566 12.2207L20.1166 10.9607C20.5566 10.5207 21.1166 10.2807 21.7266 10.2207V9.2207L15.7266 3.2207H5.72656C4.61656 3.2207 3.72656 4.1107 3.72656 5.2207V19.2207C3.72656 20.3207 4.61656 21.2207 5.72656 21.2207H11.7266V19.3507L11.8566 19.2207H5.72656V5.2207H12.7266V12.2207H18.8566ZM14.7266 4.7207L20.2266 10.2207H14.7266V4.7207ZM19.8566 14.0507L21.8966 16.0907L15.7666 22.2207H13.7266V20.1807L19.8566 14.0507ZM23.5766 14.4107L22.5966 15.3907L20.5566 13.3507L21.5366 12.3707C21.7266 12.1707 22.0566 12.1707 22.2566 12.3707L23.5766 13.6907C23.7766 13.8907 23.7766 14.2207 23.5766 14.4107Z"
        fill="#fff"
      />
    </svg>
  );
};

export default MyNotesSvg;
