import React from "react";

const MockInfo = (props) => {
  return (
    <svg
      width="21"
      height="25"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.90875 0.631676C8.84253 0.639451 8.64129 0.661743 8.46154 0.681248C7.32656 0.804403 6.06969 1.23029 5.05548 1.83543C3.76469 2.60556 2.71557 3.65469 1.94544 4.94548C1.34524 5.95139 0.946606 7.12037 0.783959 8.35154C0.728264 8.77305 0.728264 10.0629 0.783959 10.4844C0.946606 11.7156 1.34524 12.8845 1.94544 13.8905C2.71557 15.1812 3.76469 16.2304 5.05548 17.0005C6.06139 17.6007 7.23037 17.9993 8.46154 18.162C8.88306 18.2177 10.1729 18.2177 10.5944 18.162C12.7365 17.879 14.5522 16.9381 16.034 15.3434C16.8538 14.4612 17.4952 13.3638 17.8888 12.17C18.1991 11.229 18.3152 10.4797 18.3152 9.41797C18.3152 8.63253 18.282 8.28552 18.1411 7.59472C17.6576 5.22619 16.1359 3.10954 14.0005 1.83543C13.0048 1.24136 11.8175 0.834297 10.6288 0.679425C10.3271 0.640139 9.12204 0.606667 8.90875 0.631676ZM10.1272 3.12051C10.694 3.31869 11.1287 3.75486 11.3228 4.3201C11.4349 4.64643 11.4349 5.17648 11.3228 5.5028C11.1293 6.06619 10.7032 6.49492 10.1272 6.7057C9.83514 6.81258 9.22081 6.81258 8.92871 6.7057C8.35273 6.49492 7.92661 6.06619 7.73314 5.5028C7.62106 5.17648 7.62106 4.64643 7.73314 4.3201C7.95547 3.67261 8.4859 3.20376 9.16676 3.05284C9.3861 3.00423 9.89775 3.04029 10.1272 3.12051ZM10.904 10.9316V14.0277H11.42H11.936V14.5437V15.0597H9.52797H7.11991V14.5437V14.0277H7.63592H8.15194V11.4476V8.86755H7.63592H7.11991V8.35154V7.83553H9.01196H10.904V10.9316Z"
        fill={props.color}
      />
    </svg>
  );
};

export default MockInfo;
