import React from "react";

const MyPointsSvg = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7266 4.95801C16.7874 4.95801 17.8048 5.37944 18.555 6.12958C19.3051 6.87973 19.7266 7.89714 19.7266 8.95801C19.7266 10.0189 19.3051 11.0363 18.555 11.7864C17.8048 12.5366 16.7874 12.958 15.7266 12.958C14.6657 12.958 13.6483 12.5366 12.8981 11.7864C12.148 11.0363 11.7266 10.0189 11.7266 8.95801C11.7266 7.89714 12.148 6.87973 12.8981 6.12958C13.6483 5.37944 14.6657 4.95801 15.7266 4.95801ZM15.7266 6.85801C15.1696 6.85801 14.6355 7.07926 14.2416 7.47308C13.8478 7.86691 13.6266 8.40105 13.6266 8.95801C13.6266 9.51496 13.8478 10.0491 14.2416 10.4429C14.6355 10.8368 15.1696 11.058 15.7266 11.058C16.8866 11.058 17.8266 10.118 17.8266 8.95801C17.8266 7.79801 16.8866 6.85801 15.7266 6.85801ZM15.7266 13.958C18.3966 13.958 23.7266 15.288 23.7266 17.958V20.958H7.72656V17.958C7.72656 15.288 13.0566 13.958 15.7266 13.958ZM15.7266 15.858C12.7266 15.858 9.62656 17.318 9.62656 17.958V19.058H21.8266V17.958C21.8266 17.318 18.6966 15.858 15.7266 15.858ZM5.72656 14.238L3.22656 15.728L3.90656 12.918L1.72656 11.038L4.59656 10.788L5.72656 8.14801L6.83656 10.788L9.72656 11.038L7.52656 12.918L8.17656 15.728L5.72656 14.238Z"
        fill={"#fff"}
      />
    </svg>
  );
};

export default MyPointsSvg;
