import React from "react";

const SolutionTimer = (props) => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.85337 6.74328H9.69952V12.2817H7.85337V6.74328ZM11.5457 0.281738H6.00721V2.12789H11.5457V0.281738ZM8.77644 17.8202C5.20413 17.8202 2.3149 14.931 2.3149 11.3587C2.3149 7.78635 5.20413 4.89712 8.77644 4.89712C12.3487 4.89712 15.238 7.78635 15.238 11.3587C15.8841 11.3587 16.4934 11.4787 17.0841 11.6817V11.3587C17.0841 9.40174 16.4011 7.60174 15.2657 6.1802L16.5764 4.86943C16.1611 4.39866 15.7457 3.97405 15.2749 3.56789L13.9641 4.89712C12.5334 3.73405 10.7334 3.05097 8.77644 3.05097C6.57311 3.05097 4.46001 3.92624 2.90202 5.48424C1.34402 7.04223 0.46875 9.15532 0.46875 11.3587C0.46875 13.562 1.34402 15.6751 2.90202 17.2331C4.46001 18.7911 6.57311 19.6664 8.77644 19.6664C9.32106 19.6664 9.84721 19.611 10.3549 19.5094C10.0688 18.974 9.86567 18.374 9.77337 17.7371C9.44106 17.7833 9.11798 17.8202 8.77644 17.8202ZM17.398 13.9802L14.0841 17.294L12.6164 15.8264L11.5457 16.8971L14.0841 19.6664L18.4688 15.2817L17.398 13.9802Z"
        fill={props.status ? "#10a54e" : "#EE2D3B"}
      />
    </svg>
  );
};

export default SolutionTimer;
