import React from "react";

const MyQuestionSvg = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2266 14.6963V16.1963H19.7266V14.6963H21.2266ZM19.2266 9.69629H17.7266V9.19629C17.7266 8.40064 18.0426 7.63758 18.6052 7.07497C19.1679 6.51236 19.9309 6.19629 20.7266 6.19629C21.5222 6.19629 22.2853 6.51236 22.8479 7.07497C23.4105 7.63758 23.7266 8.40064 23.7266 9.19629C23.7266 10.1663 23.2266 11.0763 22.4366 11.6063L22.1366 11.7963C21.5666 12.1963 21.2266 12.8063 21.2266 13.4963V13.6963H19.7266V13.4963C19.7266 12.3063 20.3266 11.1963 21.3166 10.5463L21.6066 10.3563C21.9966 10.0963 22.2266 9.66629 22.2266 9.19629C22.2266 8.79846 22.0685 8.41693 21.7872 8.13563C21.5059 7.85432 21.1244 7.69629 20.7266 7.69629C20.3287 7.69629 19.9472 7.85432 19.6659 8.13563C19.3846 8.41693 19.2266 8.79846 19.2266 9.19629V9.69629ZM9.72656 13.1963C12.3966 13.1963 17.7266 14.5363 17.7266 17.1963V20.1963H1.72656V17.1963C1.72656 14.5363 7.05656 13.1963 9.72656 13.1963ZM9.72656 4.19629C10.7874 4.19629 11.8048 4.61772 12.555 5.36786C13.3051 6.11801 13.7266 7.13542 13.7266 8.19629C13.7266 9.25715 13.3051 10.2746 12.555 11.0247C11.8048 11.7749 10.7874 12.1963 9.72656 12.1963C8.6657 12.1963 7.64828 11.7749 6.89814 11.0247C6.14799 10.2746 5.72656 9.25715 5.72656 8.19629C5.72656 7.13542 6.14799 6.11801 6.89814 5.36786C7.64828 4.61772 8.6657 4.19629 9.72656 4.19629ZM9.72656 15.0963C6.75656 15.0963 3.62656 16.5563 3.62656 17.1963V18.2963H15.8266V17.1963C15.8266 16.5563 12.6966 15.0963 9.72656 15.0963ZM9.72656 6.09629C9.16961 6.09629 8.63546 6.31754 8.24164 6.71136C7.84781 7.10519 7.62656 7.63933 7.62656 8.19629C7.62656 8.75324 7.84781 9.28739 8.24164 9.68121C8.63546 10.075 9.16961 10.2963 9.72656 10.2963C10.2835 10.2963 10.8177 10.075 11.2115 9.68121C11.6053 9.28739 11.8266 8.75324 11.8266 8.19629C11.8266 7.63933 11.6053 7.10519 11.2115 6.71136C10.8177 6.31754 10.2835 6.09629 9.72656 6.09629Z"
        fill={"#fff"}
      />
    </svg>
  );
};

export default MyQuestionSvg;
